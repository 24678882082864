require('./bootstrap');

window.AOS = AOS = require("../vendor/aos/aos");
require("../vendor/bootstrap/js/bootstrap");
window.GLightbox = GLightbox = require("../vendor/glightbox/js/glightbox");
require("../vendor/waypoints/noframework.waypoints");
window.Swiper = Swiper = require("../vendor/swiper/swiper-bundle.min");
window.Isotope = Isotope = require("../vendor/isotope-layout/isotope.pkgd");

require("./main");

// Developer specific custom js
require('./custome');
window.bootstrap = require('bootstrap/dist/js/bootstrap')
require('owl.carousel')
require('./util')
